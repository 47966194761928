@charset "UTF-8";
/* Css link*/
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #FF7800; }

.color-secondary {
  color: #3E66FB; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #FFC107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #DCD9E1; }

.color-gray {
  color: #A8A7AC; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #FF7800; }
  .bg-brand.bg-light {
    background-color: #ffe4cc; }

.bg-secondary {
  background-color: #3E66FB; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #FFC107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #A8A7AC; }
  .bg-gray.bg-light {
    background-color: #CAC8CF; }

.bg-lightgray {
  background-color: #DCD9E1; }
  .bg-lightgray.bg-light {
    background-color: #ECEAF2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: .5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: .5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: .5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: .5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

::selection {
  background-color: orange; }

.box {
  width: 100%;
  height: 3.4rem;
  position: relative;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); }
  .box span {
    position: absolute;
    width: 100%;
    height: 1rem;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white !important;
    padding: 0 5px;
    line-height: auto;
    font-size: 0.7rem;
    font-weight: 400;
    overflow: hidden; }

.ap-label {
  display: inline-block;
  padding: .4rem 1rem;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  line-height: auto;
  font-size: 0.9rem;
  font-weight: 700;
  margin-bottom: 1rem; }
  .ap-label::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 100px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    background-color: black; }

#root .app-container.app-theme-pnt-dark .ap-label, #root .app-container.app-theme-pnt-plant .ap-label {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(0, 0, 0, 0.75); }

#root .app-container.app-theme-pnt-dark .bg-light, #root .app-container.app-theme-pnt-dark .bg-l-1, #root .app-container.app-theme-pnt-dark .bg-l-2, #root .app-container.app-theme-pnt-dark .bg-l-3, #root .app-container.app-theme-pnt-dark .bg-warning, #root .app-container.app-theme-pnt-plant .bg-light, #root .app-container.app-theme-pnt-plant .bg-l-1, #root .app-container.app-theme-pnt-plant .bg-l-2, #root .app-container.app-theme-pnt-plant .bg-l-3, #root .app-container.app-theme-pnt-plant .bg-warning {
  color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-dark .bg-light *, #root .app-container.app-theme-pnt-dark .bg-l-1 *, #root .app-container.app-theme-pnt-dark .bg-l-2 *, #root .app-container.app-theme-pnt-dark .bg-l-3 *, #root .app-container.app-theme-pnt-dark .bg-warning *, #root .app-container.app-theme-pnt-plant .bg-light *, #root .app-container.app-theme-pnt-plant .bg-l-1 *, #root .app-container.app-theme-pnt-plant .bg-l-2 *, #root .app-container.app-theme-pnt-plant .bg-l-3 *, #root .app-container.app-theme-pnt-plant .bg-warning * {
    color: rgba(0, 0, 0, 0.6); }

.mobile-page-title {
  display: none; }
  @media (max-width: 570px) {
    .mobile-page-title {
      background: rgba(255, 255, 255, 0.55);
      display: flex;
      padding: 1.5rem;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
      font-size: 1.1rem;
      font-weight: 700; }
      .mobile-page-title .menu-sub {
        color: rgba(0, 0, 0, 0.35); }
      .mobile-page-title .icon-next {
        opacity: .4; }
      .mobile-page-title .menu-main {
        margin-left: .3rem; } }

.search-open {
  width: 100%;
  display: flex;
  align-items: start; }

.search-close {
  width: 100%;
  display: flex;
  align-items: start; }

.widget-search .filter_ver4.absolute-filter {
  position: absolute;
  width: 100%;
  z-index: 1001;
  padding: 10px; }
  .widget-search .filter_ver4.absolute-filter .search-box {
    margin-top: 0; }
    .widget-search .filter_ver4.absolute-filter .search-box .filter-list {
      flex-wrap: wrap; }
  .widget-search .filter_ver4.absolute-filter .filter-box {
    margin-bottom: 0; }
    @media (max-width: 570px) {
      .widget-search .filter_ver4.absolute-filter .filter-box {
        margin-bottom: 1.5rem; } }

.widget-edit-btn {
  margin-right: 9px;
  display: flex; }
  @media (max-width: 570px) {
    .widget-edit-btn {
      position: absolute;
      top: 15px;
      right: 5px; } }

@media (max-width: 758px) {
  .btn-mobile-search {
    position: absolute !important;
    top: 5px;
    right: 15px; }
    .btn-mobile-search.folded {
      transition: all .5s;
      opacity: 0;
      visibility: hidden; } }

.map-text {
  text-align: center !important;
  color: rgba(0, 0, 0, 0.75) !important; }
  .map-text.checkbox-container {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #656467;
    padding: 0 3px; }
    .map-text.checkbox-container p {
      color: rgba(0, 0, 0, 0.75) !important; }

.leaflet-container .popup-open {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  max-width: 100%;
  height: 88%; }
  .leaflet-container .popup-open.floorDetail {
    max-width: 100%;
    width: max-content;
    height: 370px;
    color: #000; }
  .leaflet-container .popup-open.filtering {
    max-width: 100%;
    width: max-content;
    height: 325px; }
  .leaflet-container .popup-open .header {
    width: 25%;
    text-align: center; }

.leaflet-container .custom-control {
  display: flex; }

.leaflet-container .toast-body {
  height: 280px; }

.leaflet-control .item-container {
  display: inline-block;
  color: #000;
  font-weight: 500;
  margin: 0 0.5rem 0 0.5rem;
  padding: 0.35rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #656467; }

.leaflet-control-layers-overlays span {
  color: rgba(0, 0, 0, 0.75) !important; }

.custom-tooltip {
  margin: 0;
  padding: 10px;
  background-color: white;
  border: 1px solid #cccccc;
  white-space: nowrap; }

.custom-tooltip span {
  text-align: center !important;
  color: rgba(0, 0, 0, 0.75) !important; }

.custom-widget-search {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.filter__options .select-container {
  width: 100%; }

.table .tr {
  height: 38px; }

.card-custom {
  z-index: 0;
  width: 20rem;
  margin: auto auto 3rem; }

.card-body .scrollbar-container {
  width: 100%;
  overflow: auto;
  height: 100%; }

.item-container {
  display: inline-block;
  color: #000;
  font-weight: bold;
  margin: 0 0.5rem 0 0.5rem;
  padding: 0.35rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #656467; }

.monitoring-img {
  position: relative;
  background-color: white;
  border-radius: .5rem;
  overflow: hidden;
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03);
  text-align: center;
  padding: .3rem; }

.report-container .popup-open {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  max-width: 50vw;
  width: 50vw;
  height: 70%;
  padding-bottom: 2rem; }

.report-container .toast-body {
  height: 100%; }

.list-group-item.layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer; }

.list-group-item:hover {
  font-weight: 700; }

.block-overlay-dark {
  height: 100%; }

@media (max-width: 758px) {
  .count-container {
    padding-top: 1rem; } }

@media (max-width: 758px) {
  .btn-yesterday {
    width: 100%; } }

.flx-row.row-space-around {
  justify-content: space-around; }

.location-log-list.filter_ver4 {
  padding-bottom: 0; }

.nav-child-active .metismenu-icon {
  font-weight: bolder;
  transform: scale(1.1); }

.nav-child-active > .metismenu-link span {
  transform: scale(1.1); }

.alarm-list {
  display: flex;
  flex-direction: column;
  flex: 1; }

.alarm-tracing-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem; }

.alert-location-popup {
  position: absolute;
  z-index: 1000;
  max-width: 50vw !important;
  width: 50vw !important;
  height: 70%;
  padding-bottom: 2rem; }
  .alert-location-popup .toast-body {
    height: 100%; }

.datePicker-container input {
  height: 38px; }

.sensor-status-container .popup-open {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  max-width: 50vw;
  width: 50vw;
  height: 70%;
  padding-bottom: 2rem;
  overflow: hidden; }
  .sensor-status-container .popup-open .toast-body {
    height: 100%; }
  @media (max-width: 758px) {
    .sensor-status-container .popup-open {
      width: 90%;
      max-width: 100vw; }
      .sensor-status-container .popup-open .toast-body {
        height: 100%;
        overflow: scroll; } }

.sensor-status-container .sensor-count-card {
  min-width: 100%; }
  .sensor-status-container .sensor-count-card .card-body {
    min-width: max-content; }

.sensor-status-container .sensor-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.status-count-container {
  font-weight: 500;
  font-size: 0.88rem;
  text-align: center;
  word-break: keep-all;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #DCD9E1; }

.selected-color {
  background-color: #d8e0fe !important;
  color: black; }

.sensor-report-container {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(350px, auto));
  grid-template-rows: 200px max-content; }
  .sensor-report-container .sensor-report-item {
    align-self: center; }
    .sensor-report-container .sensor-report-item:first-child {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }

@media print {
  .print .search-group-print {
    flex-direction: row !important; }
  @page {} }

.nav-child-active .metismenu-icon {
  font-weight: bolder;
  transform: scale(1.1);
  color: #656467; }
