@import "../pnt/tools.scss";

$btn-org-bg: #FF7800;

.btn-white{
  background-color: white;
  border: 1px solid $gray-700;
}

//
.app-theme-pnt-dark{
    .custom-action-btn{
      color:  white !important;
      background-color: transparent !important;
    }
  .bg-transparent-btn{
    background-color: transparent !important;
  }
}
.app-theme-pnt-light,.app-theme-pnt-navy {
  .custom-action-btn {
    color: #6748E3 !important;
  }
}

.text-overflow{
  overflow-y: scroll;
}

.search-wrap {
  .pnt-btn.btn-icon {
    min-width: 5.8rem;
  }
}

.table-btn{
  height: 27px;
}

.pnt-nameplate {
  .transform-scale-bigger {
    transform: scale(1.6) translateX(1.5px);
  }
}

.select-menu {
  .disabled-select-option {
    cursor: not-allowed !important;

    & > div, & > label {
      pointer-events: none;
    }
  }
  .disabled-tree-option {
    .rct-text {
      cursor: not-allowed !important;
      &>label {
        pointer-events: none;
      }
    }
  }

  .disabled-option {
    filter: opacity(0.5);
    .styled-option {
      cursor: not-allowed;
      &>label {
        pointer-events: none;
      }
    }
  }
}
.app-theme-pnt-dark .right-wrapper .right-header{
  background-color: #2C2C30;
}

.app-theme-pnt-plant .right-wrapper .right-header{
  background-color: #344545;
}

.right-wrapper {
  width: 100%;
  height: 16rem;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  margin-right: 2rem;
  overflow: auto;
  .right-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    background-color: #f2f2f2;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 2rem 1rem;
    border-bottom: 1px solid #cfcfcf;
  }
  .transform-scale-medium {
    transform:scale(1.3);
  }
}

.block-ui.cursor-not-allowed .block-ui-container {
  cursor: not-allowed;
}

.w-px-150
{
  width:150px;
}

.totalNum{
  color: #c2c0c0;
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 0.3rem;
}
.borderBottom{

  width: 100%;
  border-bottom: 2px solid #e6e6e6;
  margin:10px 0;
  padding-bottom: 20px;
}
.upper-section-in-utilization
{
  width: 100%;
  border-bottom: 2px solid #e6e6e6;
  padding-bottom: 20px;
}
.totalEtc{
  font-weight: bold;
  color: #666666;
}
.totalNumEtc{
  font-weight: bold;
  color: #a8a8a8;
}
.cardWrapperInRealtime
{
  width: 100%;
  height: 250px;
  & .card-body {
    padding-top: 0;
  }
}

.simple-marker {
  width: 15px;
  height: 15px;
  transform: translate(7.5px 7.5px);
  background-color: #3076c1;
  border-radius: 50%;
}

.none-under-770
{
  @media only screen and (max-width: 769px) {
    display: none;
  }
}

.none-over-770
{
  @media only screen and (min-width: 770px) {
    display: none;
  }
}

.margin-left-under-770
{
  @media only screen and (max-width: 769px) {
    margin-left: 1rem;
  }
}

.margin-bottom-under-770
{
  @media only screen and (max-width: 769px) {
    margin-bottom: 2rem;
  }
}

.change-cursor
{
  .block-ui-overlay
  {
    cursor:not-allowed;
  }
  .block-ui-message
  {
    cursor:not-allowed;
  }
}

.transform-scale-smaller
{
  transform:scale(0.6);
  margin-bottom: 1px;
}
.transform-scale-bigger
{
  transform:scale(1.6);
}
.transform-scale-medium
{
  transform:scale(1.3);
}

.block-ui-message-style
{
  padding: 15px;
  width: 150px;
  height: 150px;
  background-color: rgba(196, 196, 196,0.8);
  font-size:13px;
}

.set-center{
  &.row{
    display:flex;
    align-items: center;
  }

}

.dropdown-menu {
  &.dropdown-menu-sm {
    min-width: 10rem;
  }

}

.disabled-select-option {
  cursor: not-allowed !important;
  &>div {
    pointer-events: none;
  }
}

.borderBottomInUtilization{
  border-bottom: 3px solid #e6e6e6;
}

.border-orange{
  border: 2px solid orange;
  box-shadow: none;
}
.styled-footer{
  height: 5rem;
  background-color: #f8f9fa;
  border-top: 1px solid #d1d1d1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.styled-modal-title{
  font-size: 0.8rem;
  font-weight: bold;
}

.styled-modal-subTitle {
  font-size: 0.7rem;
  font-weight: 400;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}

.grid-layout-container{
  display: grid;
  height: calc(100vh - 60px - 30px - 45px);
  grid-template-columns: 100%;
  grid-template-rows: max-content auto;

  .table-container{
    height: 100%;
  }
  @media(max-width: $breakpoint-tablet){
    height: 900px;
  }
}

.application-grid-container{
  display: grid;
  min-height: calc(100vh - 60px - 30px - 45px);
  grid-template-columns: 100%;
  grid-template-rows: max-content auto;

  & .application-contents-container{
    display: grid;
    height: 100%;
    grid-template-rows: repeat(8, auto);
    grid-template-columns: 100%;
    align-items: center;

    & .details-input-group{
      display: grid;
      grid-template-columns: 12rem auto;
      min-height: 25px;
    }
  }

  @media(max-width: $breakpoint-tablet){
    height: 800px;

    .application-contents-container {
      & .details-input-group {
        grid-template-columns: 10rem auto;
      }

      & .required-input {
        grid-template-columns: auto;
      }
    }
  }
}

.borrowed-assets-container{
  .card-body {
    height: 19rem;
    width: calc(100% - 1px);
    align-self: center;
  }
}

.table-btn-container{
  .pnt-txt{
    border: none;
    border-radius: 3px;
    color: $white;
    height: 26px;

    &.txt-btn-org{
      background: $btn-org-bg;
    }

    &:hover{
      -webkit-filter: brightness(1.2);
      filter: brightness(1.2);
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }
  }
}

.process-grid-container{
  display: grid;
  min-height: calc(100vh - 60px - 30px - 36px);
  grid-template-columns: 100%;
  grid-template-rows: repeat(auto-fill, minmax(min-content, calc((100% - 26rem) / 2)));

  & .loanable-assets{
    min-height: 22rem;
  }

  & .process-detail-container {
    & .process-contents-container {
      display: grid;
      grid-template-rows: repeat(8, auto);

      & .details-input-group {
        display: grid;
        grid-template-columns: 12rem auto;
        min-height: 25px;
      }

      @media(max-width: $breakpoint-tablet) {
        height: 800px;

        & .details-input-group {
          grid-template-columns: 10rem auto;
        }
      }
    }
  }
}

.rental-period-container{

  & .pnt-datepicker-container{
    flex: 47%;
  }

  & .label-options{
    @media(max-width: $breakpoint-tablet) {
      display: inline;
      text-align: center;
    }
  }
}

.closed-sidebar {
  & .app-sidebar{
    z-index: 501;
  }
}

.application-contents-container{
  & .details-input-group{
    & .label-options{

    }
  }
}

.grid-layout-container-for-assetDetail
{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

@media(max-width:1150px) {
  .grid-layout-container-for-assetDetail
  {
    row-gap: 20px;
    grid-template-columns: 1fr;

  }
}

@media(max-width:730px) {
  .grid-layout-container-for-assetDetail
  {

    display:block;

  }
}

@media(max-width:420px) {
  .inspection-status-list
  {
    flex-direction:column;
  }
}

.timeline-height{
  height:600px;
  margin-bottom:20px
}



.validation-input-container{
  &::after {
    display: block;
    content: '*';
    position: absolute;
    top: -.8rem;
    left: -.3rem;
    color: #FF7800FF;
  }

  .form-must {
    &.pnt-input {
      border: 1px solid #FF7800FF;
      box-shadow: none;
      position: relative;
    }
  }
}

.required-input{
  & .label-options{
    & .pnt-datepicker-container{
      border: 1px solid #FF7800FF;
      border-radius: 5px;

      &::after {
        display: block;
        content: '*';
        position: absolute;
        top: -.8rem;
        left: -.3rem;
        color: #FF7800FF;
      }
    }
  }
}
.pnt-input.non-active {
  background-color: #f2f2f2;
}

.asset-tab-height
{
  width:700px;
}

.sticky-header {
  position: sticky;
  z-index: 1;
  top: 0;
}

.custom-card-layout{
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-wrapper .edge .edge-text.edge-handle-text {
  fill: #fff;
  stroke: #fff;
}

.graph .entities {
  display: none;
}

// 전환 트리거
.transition-trigger-container{
  display: flex;
  justify-content: center;
  width: 750px;
  max-width: none;

  & .modal-content{

    & .trigger-description {
      & .description-value {
        padding: 0 15px;
        font-weight: 700;

        .description-text {
          margin-left: 1rem;
          font-weight: 500;
        }
      }
    }

    & .trigger-btn-container {
      display: flex;
      justify-content: flex-end;
    }

    & .trigger-body-container {
      min-height: 250px;
      max-height: 450px;
      overflow: auto;
      & .btn-icon-only>span {
        margin-top: 0;
      }
      & .input-container-group {
        display: grid;
        grid-template-rows: min-content min-content;
        margin-top: 1rem;
        border-top: 1px solid #ddd;
      }

      & .input-container {
        margin-top: 5px;
        display: grid;
        grid-gap: 5px;
        align-items: center;
        grid-template-columns: 120px 150px 150px auto min-content;
        &.trigger-group {
          align-items: start;
          grid-template-columns: 120px auto;

          & .location-input-group {
            &:not(:first-child) {
              margin-top: 5px;
            }
            display: grid;
            grid-gap: 5px;
            align-items: center;
            grid-template-columns: 150px 150px auto min-content;
          }
        }

        &.api-input{
          grid-template-columns: 120px auto;
        }

        & .input-row {
          grid-column: 1 / -1;
          display: grid;
          grid-gap: 5px;
          grid-template-columns: repeat(3, 13.3rem) auto;
        }

        & .input-label{
          margin: 0;
        }
      }

      & .react-dropdown-tree-select .dropdown .dropdown-trigger {
        display: inline-flex;
      }
    }
  }
}

// 사이드바 하단
.app-main {
  .pnt-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pnt-sidebar-bottom {
      padding: 1rem 1.5rem 1.5rem;
      .menu-border-box {
        border-top: 1px solid var(--font-black);
      }
    }
    .app-sidebar__inner {
      max-height: calc(100vh - 145px);
    }
  }
}

.closed-sidebar .app-main .pnt-sidebar .metismenu-container .menu-border-box {
  border: none;
}
.closed-sidebar .app-main .app-sidebar.pnt-sidebar:hover .scrollbar-container .metismenu-container.visible .menu-border-box {
  border-bottom: 1px solid var(--blue);
}
.closed-sidebar .app-main .app-sidebar.pnt-sidebar:hover .pnt-sidebar-bottom .menu-border-box {
  border-top: 1px solid var(--font-black);
}
.closed-sidebar:not(.sidebar-mobile-open) .app-sidebar:hover .scrollbar-container {
  height: calc(100% - 140px);
}
// 사이드바 하단 종료 //

.cursor-default{
  cursor: default;
}

.app-theme-pnt-dark .am__asset-picture{
  background-color: #29292D;
  box-shadow: none;
}

.app-theme-pnt-plant .am__asset-picture{
  background-color: #243031;
  box-shadow: none;
}

.am__asset-picture{
  width: 8.5rem;
  height: 8.5rem;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0.47rem 2.19rem rgba(217, 217, 217,0.3), 0 0.94rem 1.41rem rgba(217, 217, 217,0.3), 0 0.25rem 0.53rem rgba(217, 217, 217,0.3), 0 0.13rem 0.19rem rgba(217, 217, 217,0.3);
}

.am__asset-picture span{
  position: absolute;
  top: 45%;
  left: 44%;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: .2;
}

.scale-08 {
  transform: scale(0.8);
}

.font-1rem {
  font-size: 1rem;
}

.w-auto {
  width: auto;
}

.input-form-group {
  padding-left: 0;
  padding-right: 30px;
  margin-left: 15px;
}

.w-340{
  width: 340px;
}

.location-information-card{
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media(max-width: 900px){
    grid-template-columns: 1fr;
  }
}

.d-column-under-900 {
  .asset-information-width{
    flex: 2;
  }
  .asset-tab-width{
    flex: 3;
  }
  @media(max-width: 900px){
    flex-direction: column;
  };
}

.widget-filter-height{
  height: 44px;
}

//디테일 - 라인 라벨
.am__graph--horizon-line__label{
  display: flex;
  gap: 5%;
  flex-wrap: wrap;
  justify-content: flex-end;
  & .label-elem{
    & .label-bg{
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
    & .label-small{
      font-size: 6pt;
      color: $f-b-5;
    }
  }
}

.filter__options{
  display: contents;
}

.Rental-date-picker{
  display: grid;
  place-items: center;
  width: initial;
  @media(max-width: 991px) {
    width: 100%;
  }
}

.table-cell-middle{
  display: table-cell !important;
  vertical-align: middle;
}

.filter_ver4 .filter-box:last-child {
  margin-bottom: 0;
}

.width-150px{
  width: 150px;
}

.w-230px{
  width: 230px;
}

.group-single-tree-select {
  .tag-list{
    height: 28px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .node.leaf{
    padding: 3px 8px 0 0;
    font-size: 0.8rem;
  }
}