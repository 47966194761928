.ReactTable {
    .rt-td,
    .rt-thead .rt-td {
        padding: 0.25rem;
    }
    .rt-th,
    .rt-thead .rt-th {
        padding: 0.4rem 0.25rem;
    }
    .rt-tr {
        font-size: 1rem;
    }
    .rt-tbody .rt-td {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .rt-expander {
        margin: 0;
    }
}

.ReactTable {
    .f-1 {
        flex: 1 0 10px !important;
    }
    .f-2 {
        flex: 1 0 20px !important;
    }
    .f-3 {
        flex: 1 0 30px !important;
    }
    .f-4 {
        flex: 1 0 40px !important;
    }
    .f-5 {
        flex: 1 0 50px !important;
    }
    .f-6 {
        flex: 1 0 60px !important;
    }

    .f-1-p {
        flex: 1 0 10% !important;
    }
    .f-2-p {
        flex: 1 0 20% !important;
    }
    .f-3-p {
        flex: 1 0 30% !important;
    }
    .f-4-p {
        flex: 1 0 40% !important;
    }
    .f-5-p {
        flex: 1 0 50% !important;
    }
    .f-6-p {
        flex: 1 0 60% !important;
    }
}

.ReactTable.interactive-row {
    .rt-tbody {
        .rt-tr {
            cursor: pointer;
        }
        .rt-tr.-padRow {
            cursor: default;
        }
    }
}
.ReactTable.window-grid {
    .rt-tbody {
        overflow-y: hidden;
    }
}

.table-wrapper
{
    height: 100%;
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
    }
    .table {
        display: inline-block;
        border-spacing: 0;
        .border-box {
            height: 100%;
            border-top: 2px solid black;
            border-bottom: 2px solid black;

            .tbody {
                .tr {
                    :nth-child(2n) {
                        background-color: rgba(0, 0, 0, 0.02);
                    }
                    :hover {
                        background-color: #ebf0fa;
                    }
                }
                .tr.clickable {
                    :hover {
                        cursor: pointer;
                    }
                }
            }
            .tr {
                :last-child {
                    .td {
                        border-bottom: 0;
                    }
                }
            }
            .th {
                font-weight: 700;
                background-color: #d8e0fe;

            }
            .th,
            .td {
                margin: 0;
                padding: 0.5rem;
                border-bottom: 1px solid #ebebeb;
            }
        }
    }
}