@charset "UTF-8";
/* Css link*/
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #FF7800; }

.color-secondary {
  color: #3E66FB; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #FFC107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #DCD9E1; }

.color-gray {
  color: #A8A7AC; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #FF7800; }
  .bg-brand.bg-light {
    background-color: #ffe4cc; }

.bg-secondary {
  background-color: #3E66FB; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #FFC107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #A8A7AC; }
  .bg-gray.bg-light {
    background-color: #CAC8CF; }

.bg-lightgray {
  background-color: #DCD9E1; }
  .bg-lightgray.bg-light {
    background-color: #ECEAF2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: .5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: .5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: .5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: .5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.page-landering {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: auto; }
  .page-landering * {
    color: white !important; }
  .page-landering .page-landering__cont-wrap {
    width: 100%;
    height: 100%; }
    .page-landering .page-landering__cont-wrap .lander-mobile-header {
      display: none; }
    .page-landering .page-landering__cont-wrap .lander-info {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 55%;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap {
        padding-top: 5%;
        margin-right: 10%;
        width: 70%;
        min-height: 60%;
        max-width: 600px;
        min-width: 300px; }
        @media screen and (max-width: 1024px) {
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap {
            margin: 0;
            width: 100%;
            padding: 0 2rem; } }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--logo {
          display: inline-block;
          padding: .6rem .8rem .7rem 1.2rem;
          border-radius: 5rem;
          background-color: #FF7800;
          transform: scale(0.7) translateX(-20%);
          margin-bottom: 1rem;
          box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03); }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--logo .pnt-logo {
            opacity: .9; }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt {
          margin-bottom: 3rem; }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt * {
            display: block; }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__main {
            line-height: 1.1;
            font-size: 60px;
            font-weight: 700;
            margin-bottom: 5rem; }
            @media (min-width: 570px) and (max-width: 1024px) {
              .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__main {
                line-height: 1.2;
                font-size: 50px;
                font-weight: 700; } }
            @media (max-height: 570px) {
              .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__main {
                line-height: auto;
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: .8rem; } }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__sub {
            line-height: auto;
            font-size: 1.2rem;
            font-weight: 400;
            opacity: .9;
            margin-bottom: 1rem; }
            @media (max-width: 1024px) {
              .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__sub {
                line-height: auto;
                font-size: 1rem;
                font-weight: 700;
                margin-bottom: .6rem; } }
            @media (max-height: 758px) {
              .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__sub {
                line-height: auto;
                font-size: 1rem;
                font-weight: 700;
                margin-bottom: .6rem; } }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__desc {
            line-height: auto;
            font-size: 0.9rem;
            font-weight: 200;
            opacity: .7; }
            @media (max-width: 1024px) {
              .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__desc {
                line-height: auto;
                font-size: 0.8rem;
                font-weight: 200; } }
            @media (max-height: 758px) {
              .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__desc {
                line-height: auto;
                font-size: 0.8rem;
                font-weight: 200; } }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--login-btn {
          display: none; }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--language > div > div {
          border: 1px solid rgba(255, 255, 255, 0.4);
          background-color: rgba(255, 255, 255, 0.2); }
    .page-landering .page-landering__cont-wrap .lander-login {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 45%;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5); }
      .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap {
        display: flex;
        margin-left: 10%;
        width: 70%;
        max-width: 400px;
        min-width: 300px; }
        @media screen and (max-width: 1024px) {
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap {
            margin: 0;
            width: 100%;
            max-width: unset;
            min-width: unset;
            padding: 0 2rem; } }
        .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .title-box {
          flex: 0 0 auto;
          margin-bottom: 0; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .title-box .txt__main {
            line-height: auto;
            font-size: 2rem;
            font-weight: 700;
            white-space: nowrap;
            margin-bottom: 1rem; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .title-box .txt__sub {
            line-height: 1.4;
            font-size: 0.9rem;
            font-weight: 200; }
        .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box {
          flex: 1 0 auto; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box .cont--error p {
            line-height: 1.4;
            font-size: 0.9rem;
            font-weight: 700;
            color: #ff3333 !important;
            margin: 0; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box .cont--txt {
            align-items: center;
            text-align: center; }
            .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box .cont--txt .txt__main {
              line-height: 1.5;
              font-size: 1.5rem;
              font-weight: 700; }
            .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box .cont--txt .txt__sub {
              line-height: auto;
              font-size: 1rem;
              font-weight: 400; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box input:-webkit-autofill,
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box input:-webkit-autofill:hover,
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box input:-webkit-autofill:focus,
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-transition: background-color 9999s ease-out;
            -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
            -webkit-text-fill-color: #fff !important; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .cont-box input:focus {
            border: 1px solid rgba(255, 255, 255, 0.4) !important;
            -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.1) inset !important; }
        .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box {
          flex: 0 0 auto;
          margin-top: 2rem; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
            padding-right: 1.5rem;
            text-align: center; }
            .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap a, .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap button {
              height: 30px;
              opacity: .7;
              line-height: auto;
              font-size: 0.9rem;
              font-weight: 400; }
              .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap a:hover, .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap button:hover {
                color: white;
                opacity: 1; }
              @media (max-width: 758px) {
                .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap a, .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap button {
                  line-height: auto;
                  font-size: 0.7rem;
                  font-weight: 400; } }
            .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap .sign-in::before {
              content: '';
              width: 1px;
              height: 1rem;
              background-color: rgba(255, 255, 255, 0.295);
              transform: translateY(20%);
              display: inline-block;
              margin-right: 1rem; }
            .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap .to-login {
              display: flex;
              align-items: center;
              gap: 1rem;
              line-height: auto;
              font-size: 0.9rem;
              font-weight: 400; }
              .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap .to-login a, .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .bottom-box .bottom-box__wrap .to-login button {
                opacity: 1;
                padding: .2rem .8rem;
                border-radius: 100rem;
                border: 1px solid white !important; }
  .page-landering .page-landering__bg {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url("../../images/background_login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    animation-name: bg-moving;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 100s;
    -webkit-animation-name: bg-moving;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    -webkit-animation-duration: 100s;
    -moz-animation-name: bg-moving;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: alternate;
    -moz-animation-duration: 100s; }
    .page-landering .page-landering__bg::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(31, 25, 17, 0.6); }

@keyframes bg-moving {
  from {
    background-position: left; }
  to {
    background-position: right; } }

@-webkit-keyframes bg-moving {
  from {
    background-position: left; }
  to {
    background-position: right; } }

@-moz-keyframes bg-moving {
  from {
    background-position: left; }
  to {
    background-position: right; } }
  .page-landering .pnt-btn.btn-secondary:hover {
    background-color: #3E66FB;
    filter: brightness(1.2); }
  .page-landering .pnt-btn.form-disable {
    box-shadow: 0 0 0 1px #ECEAF2 inset;
    background-color: #F6F6F9;
    color: rgba(0, 0, 0, 0.45); }
    .page-landering .pnt-btn.form-disable:hover {
      filter: none; }
    .page-landering .pnt-btn.form-disable span {
      filter: unset !important;
      opacity: .4; }
  .page-landering .pnt-btn.btn-large {
    margin-top: 1rem; }
  .page-landering .pnt-input--group .pnt-input {
    color: white !important;
    letter-spacing: .5px;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
    width: 100%;
    height: 100%;
    border: 1px solid transparent !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 5px;
    padding: 0 0.8rem;
    box-sizing: border-box;
    transition: all 0.2s; }
    .page-landering .pnt-input--group .pnt-input::placeholder {
      color: white !important;
      line-height: auto;
      font-size: 0.9rem;
      font-weight: 200;
      white-space: nowrap;
      font-size: 13px;
      opacity: .6; }
  .page-landering .pnt-input--group input:focus, .page-landering .pnt-input--group input:active, .page-landering .pnt-input--group input:focus-visible {
    filter: none !important;
    background-color: rgba(255, 255, 255, 0.35);
    border: 1px solid #86858A;
    outline: none;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18); }
  .page-landering .pnt-input--group input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.35) inset; }
  .page-landering .pnt-input--group span {
    filter: invert(1);
    opacity: 1; }
  .page-landering .pnt-select--group {
    width: 150px; }
    .page-landering .pnt-select--group * {
      color: white !important; }
    .page-landering .pnt-select--group .select__btn {
      border: 1px solid rgba(255, 255, 255, 0.4);
      background-color: rgba(255, 255, 255, 0.2); }
      .page-landering .pnt-select--group .select__btn span {
        filter: invert(1);
        opacity: .8; }
    .page-landering .pnt-select--group .select__options {
      background-color: rgba(255, 255, 255, 0.3); }
      .page-landering .pnt-select--group .select__options li:hover a {
        background-color: rgba(255, 255, 255, 0.5); }
      .page-landering .pnt-select--group .select__options li.on a {
        background-color: #3E66FB;
        color: white; }
    .page-landering .pnt-select--group.on .select__btn {
      border: 1px solid white !important; }
  .page-landering .pnt-checkbox .checkbox-effect {
    border: none !important;
    background-color: rgba(255, 255, 255, 0.2) !important; }
  .page-landering .pnt-checkbox p {
    color: white; }
  .page-landering .pnt-checkbox.check-checked .checkbox-effect {
    background-color: #FF7800 !important;
    border: none !important; }
  .page-landering .form-disable.pnt-btn {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: #A8A7AC !important;
    color: white; }
    .page-landering .form-disable.pnt-btn span {
      filter: invert(1) brightness(2) !important; }
    .page-landering .form-disable.pnt-btn:hover {
      filter: none !important; }
  .page-landering .form-disable.pnt-input--group .pnt-input {
    color: white !important;
    letter-spacing: .5px;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
    width: 100%;
    height: 100%;
    border: 1px solid transparent !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-radius: 5px;
    padding: 0 0.8rem;
    box-sizing: border-box;
    transition: all 0.2s; }
    .page-landering .form-disable.pnt-input--group .pnt-input::placeholder {
      color: white !important;
      line-height: auto;
      font-size: 0.9rem;
      font-weight: 200;
      white-space: nowrap;
      font-size: 13px;
      opacity: .6; }
  .page-landering .form-disable.pnt-input--group input:focus, .page-landering .form-disable.pnt-input--group input:active, .page-landering .form-disable.pnt-input--group input:focus-visible {
    filter: none !important;
    background-color: rgba(255, 255, 255, 0.35);
    border: 1px solid #86858A;
    outline: none;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18); }
  .page-landering .form-disable.pnt-input--group span {
    filter: invert(1);
    opacity: 1; }

@media (max-width: 570px) {
  .page-landering {
    overflow: hidden; }
    .page-landering .page-landering__cont-wrap.login_clicked {
      background-color: rgba(0, 0, 0, 0.4); }
      .page-landering .page-landering__cont-wrap.login_clicked .lander-mobile-header {
        display: none; }
      .page-landering .page-landering__cont-wrap.login_clicked .lander-info {
        display: none; }
      .page-landering .page-landering__cont-wrap.login_clicked .lander-login {
        display: block; }
    .page-landering .page-landering__cont-wrap .lander-mobile-header {
      display: block;
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 56px; }
      .page-landering .page-landering__cont-wrap .lander-mobile-header .mobile-header__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 1rem; }
        .page-landering .page-landering__cont-wrap .lander-mobile-header .mobile-header__wrap .logo-box {
          display: inline-block;
          padding: .6rem .8rem .7rem 1.2rem;
          border-radius: 5rem;
          border: 1px solid white;
          transform: scale(0.5) translateX(-40%) translateY(35%);
          margin-bottom: 1rem;
          box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03); }
          .page-landering .page-landering__cont-wrap .lander-mobile-header .mobile-header__wrap .logo-box .pnt-logo {
            opacity: 1; }
        .page-landering .page-landering__cont-wrap .lander-mobile-header .mobile-header__wrap .language-box .select__options {
          background-color: #434245;
          left: unset;
          right: 0;
          transform: unset; }
    .page-landering .page-landering__cont-wrap .lander-info {
      display: block;
      position: relative;
      width: 100%;
      height: 100%; }
      .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 1.5rem;
        display: flex;
        align-items: center; }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--logo {
          display: none; }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt {
          padding: 15% 1rem; }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__main {
            line-height: auto;
            font-size: 2rem;
            font-weight: 700;
            margin-bottom: 3rem;
            position: relative; }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__sub {
            display: block;
            line-height: auto;
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 1rem; }
            .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__sub::before {
              content: '';
              width: 1.6rem;
              height: 4px;
              background-color: white;
              display: block;
              border-radius: 100rem;
              margin-bottom: .5rem; }
          .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--txt .txt__desc {
            line-height: auto;
            font-size: 0.8rem;
            font-weight: 200; }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--language {
          display: none; }
        .page-landering .page-landering__cont-wrap .lander-info .lander-info__wrap .info--login-btn {
          display: block;
          position: absolute;
          right: 1.5rem;
          bottom: 10%; }
    .page-landering .page-landering__cont-wrap .lander-login {
      display: none;
      position: relative;
      width: 100%;
      height: 100%; }
      .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap {
        margin: 0;
        padding: 2rem;
        padding-top: 30%;
        height: 100%;
        width: 100%; }
        .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .title-box {
          text-align: center; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .title-box .txt__main {
            line-height: auto;
            font-size: 1.5rem;
            font-weight: 700; }
          .page-landering .page-landering__cont-wrap .lander-login .lander-login__wrap .title-box .txt__sub {
            line-height: 1.4;
            font-size: 0.9rem;
            font-weight: 200; } }

.pnt-modal .modal-body {
  background-color: white; }
