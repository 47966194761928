@include media-breakpoint-down(xl) {

  .play-body-custom{
    background-color: #fff;
    border-radius: 0.25rem;
    padding: .5% 5%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    & .row-custom:nth-child(1){
      justify-content: center;
      margin-bottom: 0 !important;

      & .tracing-play-bar{
        border: none;
        border-radius: 100px;
        //background-color: #424764;
        height: .3rem;
        width: 100%;
        margin: 1rem 0;
        box-shadow: none;

        & .noUi-base{

          & .noUi-connects{
          }
          & .noUi-origin{
            margin-right: 1rem;

            & .noUi-handle{
              box-shadow: none;
              background-color: #3E66FB;
              width: 1rem;
              height: 1rem;
              border-radius: 100px;
              &::before{
                display: none;
              }
              &::after{
                display: none;
              }
            }
          }
        }
      }

    }

    & .row-custom:nth-child(2){
      justify-content: center;

      & > button{
        margin-right: 1rem;
        font-weight: normal;


        &:nth-child(2){
          max-height: 2rem;
          min-width: 2rem;

          & svg{
            transform: scale(.8) translateX(10%);
          }
        }
      }

      & > div{
        max-height: 2rem;
        max-width: 5rem;
      }

      & span.play-time{
        line-height: 2rem;
        margin-left: 1rem;
        color: white;
        font-family: NotoSans;
        font-size: .8rem;
      }

    }
  }
}
@include media-breakpoint-down(sm) {
  .play-body-custom{
    & .row-custom:nth-child(2){
      & > button{
        margin-right: .7rem;
      }
    }
  }
}

@media screen and (max-width: 410px){

  .play-body-custom{
    background-color: #1d2333;
    border-radius: 0.25rem;
    padding: 0rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    & .row-custom:nth-child(1){
      justify-content: center;
      margin-bottom: 0 !important;
      margin: 0 auto;
      width: 98%;

      & .tracing-play-bar{
        border: none;
        border-radius: 100px;
        background-color: #424764;
        height: .3rem;
        width: 100%;
        margin: 1rem 0;
        box-shadow: none;

        & .noUi-base{

          & .noUi-connects{
          }
          & .noUi-origin{
            margin-right: .5rem;

            & .noUi-handle{
              box-shadow: none;
              background-color: white;
              width: 1rem;
              height: 1rem;
              border-radius: 100px;
              &::before{
                display: none;
              }
              &::after{
                display: none;
              }
            }
          }
        }
      }

    }

    & .row-custom:nth-child(2){
      justify-content: space-between;
      margin-bottom: 0 !important;
      margin: 0 auto;
      width: 98%;


      & > button{
        margin-right: .5rem;
        font-weight: normal;

        &:nth-child(1){
          max-height: 2rem;
          max-width: 7rem;
          line-height: 2rem;
        }
        &:nth-child(2){
          max-height: 2rem;
          min-width: 2rem;

          & svg{
            transform: scale(.8) translateX(10%);
          }
        }
      }

      & > div{
        max-height: 2rem;
        max-width: 4rem;
      }

      & span.play-time{
        line-height: 2rem;
        margin-left: .5rem;
        color: white;
        font-family: NotoSans;
        font-size: .5rem;
      }
    }
  }
}

.btn-custom{
  background-color: transparent;
  border-radius: 18px;
  border: solid 2px #eb651e;
  width: 150px;
  height: 35px;
  padding: 0;

  font-family: NotoSans;
  font-size: 15px;
  font-weight: 500;
  color: #eb651e;
}
.btn-indigo-light{
  background-color: #3a3f5d;
  color: white;
  border: 2px solid #3a3f5d;
}