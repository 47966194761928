@charset "UTF-8";
/* Css link*/
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #FF7800; }

.color-secondary {
  color: #3E66FB; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #FFC107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #DCD9E1; }

.color-gray {
  color: #A8A7AC; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #FF7800; }
  .bg-brand.bg-light {
    background-color: #ffe4cc; }

.bg-secondary {
  background-color: #3E66FB; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #FFC107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #A8A7AC; }
  .bg-gray.bg-light {
    background-color: #CAC8CF; }

.bg-lightgray {
  background-color: #DCD9E1; }
  .bg-lightgray.bg-light {
    background-color: #ECEAF2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: .5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: .5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: .5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: .5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.pnt-btn {
  display: inline-block;
  position: relative;
  min-width: 3.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 5px;
  background-color: transparent;
  border: none !important;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  filter: brightness(1);
  transition: all 0.2s; }
  @media (max-width: 570px) {
    .pnt-btn.btn-long {
      width: 100%; } }
  .pnt-btn.btn-icon {
    min-width: 4.7rem; }
    .pnt-btn.btn-icon span {
      width: 20px;
      height: 20px;
      transform: scale(0.7) translateY(5%);
      display: inline-block;
      vertical-align: middle;
      margin: -.2rem .3rem 0 0; }
  .pnt-btn.btn-icon-only {
    padding: 0;
    min-width: 38px;
    max-width: 38px;
    max-height: 38px;
    transition: all 0.2s; }
    .pnt-btn.btn-icon-only * {
      font-size: 0; }
    .pnt-btn.btn-icon-only span {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle; }
    .pnt-btn.btn-icon-only:hover, .pnt-btn.btn-icon-only .on {
      filter: invert(0.3);
      transition: all 0.2s; }
  .pnt-btn.btn-tiny {
    height: auto;
    min-width: unset;
    padding: .3rem;
    line-height: auto;
    font-size: 0.7rem;
    font-weight: 400; }
    .pnt-btn.btn-tiny.btn-icon-only {
      padding: 0; }
      .pnt-btn.btn-tiny.btn-icon-only span {
        transform: scale(0.5); }
  .pnt-btn.btn-large {
    height: 52px;
    line-height: auto;
    font-size: 1rem;
    font-weight: 400; }
  .pnt-btn.btn-brand {
    color: white;
    background-color: #FF7800; }
    .pnt-btn.btn-brand span {
      filter: invert(1) brightness(2) !important; }
    .pnt-btn.btn-brand:hover {
      filter: brightness(1.2); }
    .pnt-btn.btn-brand.btn-shadow {
      box-shadow: 0px 5px 10px rgba(255, 120, 0, 0.3); }
  .pnt-btn.btn-secondary {
    color: white;
    background-color: #3E66FB; }
    .pnt-btn.btn-secondary span {
      filter: invert(1) brightness(2) !important; }
    .pnt-btn.btn-secondary:hover {
      filter: brightness(1.2); }
    .pnt-btn.btn-secondary.btn-shadow {
      box-shadow: 0px 5px 10px rgba(62, 102, 251, 0.3); }
  .pnt-btn.btn-success {
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    background-color: #3ac062; }
    .pnt-btn.btn-success span {
      filter: invert(0.4) brightness(0.4) !important; }
    .pnt-btn.btn-success:hover {
      filter: brightness(1.05);
      color: rgba(0, 0, 0, 0.6); }
    .pnt-btn.btn-success.btn-shadow {
      box-shadow: 0px 5px 10px rgba(58, 192, 98, 0.3); }
    .pnt-btn.btn-success:hover {
      filter: brightness(1.05); }
  .pnt-btn.btn-warning {
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    background-color: #FFC107; }
    .pnt-btn.btn-warning span {
      filter: invert(0.4) brightness(0.4) !important; }
    .pnt-btn.btn-warning:hover {
      filter: brightness(1.05);
      color: rgba(0, 0, 0, 0.6); }
    .pnt-btn.btn-warning.btn-shadow {
      box-shadow: 0px 5px 10px rgba(255, 193, 7, 0.3); }
  .pnt-btn.btn-danger {
    color: white;
    background-color: #ff3333; }
    .pnt-btn.btn-danger span {
      filter: invert(1) brightness(2) !important; }
    .pnt-btn.btn-danger:hover {
      filter: brightness(1.2); }
    .pnt-btn.btn-danger.btn-shadow {
      box-shadow: 0px 5px 10px rgba(255, 51, 51, 0.3); }
  .pnt-btn.btn-info {
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    background-color: #3bc1ff; }
    .pnt-btn.btn-info span {
      filter: invert(0.4) brightness(0.4) !important; }
    .pnt-btn.btn-info:hover {
      filter: brightness(1.05);
      color: rgba(0, 0, 0, 0.6); }
    .pnt-btn.btn-info.btn-shadow {
      box-shadow: 0px 5px 10px rgba(59, 193, 255, 0.3); }
  .pnt-btn.btn-lightgray {
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
    background-color: #DCD9E1; }
    .pnt-btn.btn-lightgray span {
      filter: invert(0.4) brightness(0.4) !important; }
    .pnt-btn.btn-lightgray:hover {
      filter: brightness(1.05);
      color: rgba(0, 0, 0, 0.6); }
    .pnt-btn.btn-lightgray.btn-shadow {
      box-shadow: 0px 5px 10px rgba(220, 217, 225, 0.3); }
    .pnt-btn.btn-lightgray:hover {
      filter: brightness(0.95); }
  .pnt-btn.btn-gray {
    color: white;
    background-color: #A8A7AC; }
    .pnt-btn.btn-gray span {
      filter: invert(1) brightness(2) !important; }
    .pnt-btn.btn-gray:hover {
      filter: brightness(1.2); }
    .pnt-btn.btn-gray.btn-shadow {
      box-shadow: 0px 5px 10px rgba(168, 167, 172, 0.3); }
  .pnt-btn.btn-darkgray {
    color: white;
    background-color: #656467; }
    .pnt-btn.btn-darkgray span {
      filter: invert(1) brightness(2) !important; }
    .pnt-btn.btn-darkgray:hover {
      filter: brightness(1.2); }
    .pnt-btn.btn-darkgray.btn-shadow {
      box-shadow: 0px 5px 10px rgba(101, 100, 103, 0.3); }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; }

.pnt-input--group {
  position: relative;
  min-width: 8rem;
  width: 12rem; }
  .pnt-input--group .pnt-input {
    width: 100%;
    height: 100%;
    border: 1px solid #ECEAF2;
    background-color: white;
    border-radius: 5px;
    padding: 0 0.8rem;
    box-sizing: border-box;
    transition: all 0.2s; }
    .pnt-input--group .pnt-input::placeholder {
      line-height: auto;
      font-size: 0.9rem;
      font-weight: 200;
      white-space: nowrap;
      font-size: 13px;
      opacity: .8; }
  .pnt-input--group input:focus, .pnt-input--group input:active, .pnt-input--group input:focus-visible {
    filter: none !important;
    background-color: white;
    border: 1px solid #86858A;
    outline: none;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18); }
  .pnt-input--group .input-error-txt, .pnt-input--group b, .pnt-input--group span, .pnt-input--group button {
    display: none; }
  .pnt-input--group span {
    position: absolute;
    opacity: .5;
    transform: scale(0.8) translateY(-50%);
    top: 50%; }
  .pnt-input--group button {
    position: absolute;
    transform: translateY(-55%) scale(1.1);
    top: 50%; }
  .pnt-input--group.icon-on-left input {
    padding-left: 2rem; }
  .pnt-input--group.icon-on-left span {
    left: .5rem;
    display: block; }
  .pnt-input--group.icon-on-right input {
    padding-left: 0.8rem; }
  .pnt-input--group.icon-on-right span {
    right: .5rem;
    display: block; }
  .pnt-input--group.btn-on-right input {
    padding-left: 0.8rem; }
  .pnt-input--group.btn-on-right button {
    right: .8rem;
    display: block; }
  .pnt-input--group.input-error {
    margin-bottom: 1rem; }
    .pnt-input--group.input-error .input-error-txt {
      display: block;
      line-height: auto;
      font-size: 0.9rem;
      font-weight: 200;
      font-size: 13px;
      color: #ff3333;
      margin-top: 6px; }
    .pnt-input--group.input-error input {
      border: 1px solid #ff3333;
      box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18); }
      .pnt-input--group.input-error input:hover {
        filter: none !important; }
  .pnt-input--group.form-disable input:focus {
    border: none !important; }

.pnt-file--group input[type="file"]::before {
  background-color: white; }

.pnt-file--group input[type="file"]::after {
  border: 1px solid #A8A7AC; }

.pnt-file--group input[type="file"]:active {
  outline: none !important;
  border: none !important; }

.pnt-file--group.file-widthout-js {
  display: flex;
  gap: .5rem;
  align-items: center;
  overflow: hidden;
  position: relative; }
  .pnt-file--group.file-widthout-js label {
    height: 100%;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center; }
  .pnt-file--group.file-widthout-js label.btn-icon {
    min-width: 5.5rem; }
  .pnt-file--group.file-widthout-js input[type="file"] {
    margin-left: -65px;
    width: 100%;
    height: 100%;
    line-height: 220%;
    position: relative;
    opacity: .5; }
    .pnt-file--group.file-widthout-js input[type="file"]::before {
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 65px;
      display: block;
      background-color: white; }
    .pnt-file--group.file-widthout-js input[type="file"]::after {
      content: '';
      display: block;
      width: calc(100% - 65px);
      height: 100%;
      position: absolute;
      top: 0px;
      left: 65px;
      border: 1px solid #A8A7AC;
      border-radius: 5px; }

.pnt-file--group.file-only-btn {
  position: relative; }
  .pnt-file--group.file-only-btn label {
    display: flex;
    margin: 0;
    align-items: center; }
  .pnt-file--group.file-only-btn input[type="file"] {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0; }

.pnt-file--group.form-h-big input[type="file"] {
  line-height: 250%; }

.pnt-file--group.form-h-small input[type="file"] {
  margin-left: -55px; }
  .pnt-file--group.form-h-small input[type="file"]::before {
    width: 5px;
    left: 55px; }
  .pnt-file--group.form-h-small input[type="file"]::after {
    width: calc(100% - 55px);
    left: 55px; }

.pnt-select--group {
  position: relative;
  min-width: 8rem;
  width: 12rem; }
  @media (max-width: 570px) {
    .pnt-select--group {
      width: 100%;
      max-width: unset; } }
  .pnt-select--group b {
    display: none; }
  .pnt-select--group .select__btn {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: .5rem;
    padding-left: 0.8rem;
    padding-right: 2rem;
    background-color: white;
    border: 1px solid #ECEAF2;
    border-radius: 5px;
    position: relative;
    transition: all 0.2s; }
    .pnt-select--group .select__btn .cont-wrap {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;
      text-align: left; }
    .pnt-select--group .select__btn .icon-down {
      position: absolute;
      right: 0;
      transform: scale(0.5);
      opacity: .5;
      margin-left: .5rem; }
    .pnt-select--group .select__btn span {
      width: 20px;
      height: 20px;
      margin-right: .5rem; }
  .pnt-select--group .select__options {
    display: none;
    z-index: 10;
    position: absolute;
    top: 100%;
    left: 1px;
    width: calc(100% - 2px);
    padding: 0;
    background-color: white;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
    border: 1px solid #ECEAF2;
    border-radius: 5px;
    overflow: hidden; }
    .pnt-select--group .select__options li a {
      display: flex;
      padding: .5rem 1rem;
      color: #000;
      border-radius: 5px;
      border-top: 1px solid #ECEAF2;
      background-color: transparent;
      text-transform: capitalize;
      transition: all 0.2s; }
      .pnt-select--group .select__options li a:last-child {
        border: none; }
      .pnt-select--group .select__options li a span {
        transform: scale(0.7);
        margin-left: -5px;
        margin-right: 7px; }
    .pnt-select--group .select__options li:hover a {
      background-color: #F6F6F9;
      transition: all 0.2s; }
    .pnt-select--group .select__options li.on a {
      background-color: #3E66FB;
      color: white; }
  .pnt-select--group.on .select__btn {
    filter: none !important;
    border: 1px solid #86858A;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
    transition: all 0.2s; }
  .pnt-select--group.on .select__options {
    display: block; }
  .pnt-select--group .cont-wrap span {
    display: none;
    position: absolute;
    opacity: .5;
    transform: scale(0.8) translateY(-60%);
    top: 50%; }
  .pnt-select--group.icon-on-left .cont-wrap {
    padding-left: 1.8rem; }
    .pnt-select--group.icon-on-left .cont-wrap span {
      left: 0;
      display: block; }
  .pnt-select--group.select-icon-only {
    width: 40px;
    min-width: unset; }
    .pnt-select--group.select-icon-only .select__btn {
      padding: 0;
      border: none;
      background-color: transparent; }
      .pnt-select--group.select-icon-only .select__btn .cont-wrap {
        width: 100%;
        height: 100%;
        position: relative; }
        .pnt-select--group.select-icon-only .select__btn .cont-wrap span {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          transform: scale(1.1) translateX(-45%) translateY(-45%); }
      .pnt-select--group.select-icon-only .select__btn .icon-down {
        display: none; }
    .pnt-select--group.select-icon-only .select__options {
      width: 10rem;
      left: 50%;
      transform: translateX(-50%); }

.pnt-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin: 0;
  margin-right: .5rem; }
  .pnt-checkbox p {
    margin: 0 0 0 .5rem;
    padding: 0;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none; }
  .pnt-checkbox input {
    display: none; }
  .pnt-checkbox .checkbox-effect {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    background-color: transparent;
    border: 1px solid #ECEAF2;
    border-radius: 5px; }
    .pnt-checkbox .checkbox-effect::before, .pnt-checkbox .checkbox-effect::after {
      content: "";
      width: 0px;
      height: 3px;
      border-radius: 100rem;
      background: white;
      position: absolute;
      transform-origin: 0% 0%; }
    .pnt-checkbox .checkbox-effect::before {
      transform: rotate(45deg) translateX(-50%) translateY(-50%);
      top: calc(50% + 3px);
      left: calc(50% - 3px);
      transition: width 50ms ease 50ms; }
    .pnt-checkbox .checkbox-effect::after {
      transform: rotate(305deg) translateX(-50%) translateY(-50%);
      top: calc(50% + 1px);
      left: calc(50% + 2px);
      transition: width 50ms ease; }
  .pnt-checkbox.check-checked .checkbox-effect {
    border: 1px solid transparent;
    background-color: #3E66FB; }
    .pnt-checkbox.check-checked .checkbox-effect::before {
      width: 6px;
      transition: width 100ms ease; }
    .pnt-checkbox.check-checked .checkbox-effect::after {
      width: 12px;
      transition: width 150ms ease 100ms; }

.pnt-toggle {
  position: relative;
  display: inline-block;
  width: 3rem;
  min-width: 3rem;
  margin: 0;
  user-select: none; }
  .pnt-toggle p, .pnt-toggle div {
    display: none; }
  .pnt-toggle input {
    opacity: 0;
    width: 0;
    height: 0; }
    .pnt-toggle input:checked + .slider {
      background-color: #3ac062; }
      .pnt-toggle input:checked + .slider::before {
        transform: translateX(26px) translateY(-50%); }
  .pnt-toggle .slider {
    background-color: #DCD9E1;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    transition: .4s; }
    .pnt-toggle .slider::before {
      z-index: 2;
      background-color: white;
      box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
      content: "";
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
      border-radius: 100rem;
      transition: .4s; }
  .pnt-toggle.toggle-txt p {
    z-index: 1;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-55%);
    margin: 0; }
    .pnt-toggle.toggle-txt p:nth-of-type(1) {
      color: gray;
      right: 5px;
      opacity: .8;
      transition: .2s; }
    .pnt-toggle.toggle-txt p:nth-of-type(2) {
      color: white;
      left: 3px;
      opacity: 0;
      transition: .2s; }
  .pnt-toggle.toggle-txt input:checked + span + p {
    opacity: 0; }
  .pnt-toggle.toggle-txt input:checked + span + p + p {
    opacity: .8; }
  .pnt-toggle.toggle-txt.txt-long {
    width: 6rem;
    min-width: 6rem; }
    .pnt-toggle.toggle-txt.txt-long input:checked + .slider {
      background-color: #3ac062; }
      .pnt-toggle.toggle-txt.txt-long input:checked + .slider::before {
        transform: translateX(4.6rem) translateY(-50%); }
    .pnt-toggle.toggle-txt.txt-long.form-h-big {
      width: 7rem;
      min-width: 7rem; }
      .pnt-toggle.toggle-txt.txt-long.form-h-big input:checked + .slider::before {
        transform: translateX(5.2rem) translateY(-50%); }
  .pnt-toggle.toggle-icon div {
    z-index: 1;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-55%) scale(0.8);
    margin: 0;
    filter: invert(1); }
    .pnt-toggle.toggle-icon div:nth-of-type(1) {
      right: 5px;
      opacity: .8;
      transition: .2s; }
    .pnt-toggle.toggle-icon div:nth-of-type(2) {
      left: 3px;
      opacity: 0;
      transition: .2s; }
  .pnt-toggle.toggle-icon input:checked + span + p + p + div {
    opacity: 0; }
  .pnt-toggle.toggle-icon input:checked + span + p + p + div + div {
    opacity: .8; }

.pnt-radio {
  display: inline-flex;
  height: 38px;
  padding: 0;
  margin: 0;
  gap: .5rem;
  width: auto; }
  .pnt-radio > li {
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    display: flex;
    border-radius: 5px;
    border: 1px solid #ECEAF2;
    background-color: transparent;
    user-select: none; }
    .pnt-radio > li input {
      visibility: hidden; }
    .pnt-radio > li label {
      transform: translateX(-10px);
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .pnt-radio > li.checked {
      background-color: #212122; }
      .pnt-radio > li.checked label {
        color: white !important; }

.pnt-nameplate {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 .5rem;
  border-radius: 5px;
  background-color: #d8e0fe;
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03);
  max-width: 8rem;
  position: relative; }
  .pnt-nameplate::before {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    background-color: white;
    top: 50%;
    left: 1.1rem;
    transform: translateY(-50%) translateX(-50%);
    border-radius: 100rem; }
  .pnt-nameplate .cont-wrap {
    color: black;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .pnt-nameplate span {
    transform: scale(0.8); }

.pnt-txt {
  margin: 0;
  padding: 0;
  line-height: auto;
  font-size: 0.9rem;
  font-weight: 400; }
  .pnt-txt.p5-n {
    line-height: auto;
    font-size: 0.7rem;
    font-weight: 400; }
  .pnt-txt.p4-n {
    line-height: auto;
    font-size: 0.8rem;
    font-weight: 400; }
  .pnt-txt.p3-n {
    line-height: auto;
    font-size: 0.9rem;
    font-weight: 400; }
  .pnt-txt.p2-n {
    line-height: auto;
    font-size: 1rem;
    font-weight: 400; }
  .pnt-txt.p1-n {
    line-height: auto;
    font-size: 1.1rem;
    font-weight: 400; }
  .pnt-txt.h5-n {
    line-height: auto;
    font-size: 1.2rem;
    font-weight: 400; }
  .pnt-txt.h4-n {
    line-height: auto;
    font-size: 1.3rem;
    font-weight: 400; }
  .pnt-txt.h3-n {
    line-height: auto;
    font-size: 1.5rem;
    font-weight: 400; }
  .pnt-txt.h2-n {
    line-height: auto;
    font-size: 1.8rem;
    font-weight: 400; }
  .pnt-txt.h1-n {
    line-height: auto;
    font-size: 2rem;
    font-weight: 400; }
  .pnt-txt.txt-light {
    font-weight: 300; }
  .pnt-txt.txt-bold {
    font-weight: 700; }
  .pnt-txt.txt-subtitle {
    font-weight: bold;
    border-top: 1px solid #A8A7AC;
    background-color: #F6F6F9;
    padding: .3rem .5rem .5rem; }
  .pnt-txt.txt-border {
    border: 1px solid #ECEAF2;
    padding: 0 .5rem;
    border-radius: 5px;
    margin: 0;
    background-color: white;
    white-space: nowrap;
    display: inline-block; }
  .pnt-txt.txt-dot {
    display: block;
    width: 100%; }
    .pnt-txt.txt-dot::before {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 100px;
      display: inline-block;
      vertical-align: middle;
      margin-right: .4rem;
      background-color: #656467; }
  .pnt-txt.txt-bg {
    padding: .5rem 1rem;
    border-radius: 5px;
    margin: 0;
    background-color: #F6F6F9; }

.pnt-border {
  background-color: #ECEAF2; }
  .pnt-border.border-h {
    display: inline-block;
    vertical-align: middle;
    margin: 0 .3rem;
    width: 1px;
    height: 32px;
    background-color: #CAC8CF; }
    .pnt-border.border-h.border-bold {
      width: 2px; }
  .pnt-border.border-w {
    width: 100%;
    height: 1px;
    margin: .6rem 0; }
    .pnt-border.border-w.border-bold {
      height: 2px; }

ul.pnt-label-3 .label-main, div.pnt-label-3 .label-main, li.pnt-label-3 .label-main {
  min-width: unset;
  width: 3rem;
  flex-shrink: 0; }

ul.pnt-label-4 .label-main, div.pnt-label-4 .label-main, li.pnt-label-4 .label-main {
  min-width: unset;
  width: 4rem;
  flex-shrink: 0; }

ul.pnt-label-5 .label-main, div.pnt-label-5 .label-main, li.pnt-label-5 .label-main {
  width: 5rem;
  flex-shrink: 0; }

ul.pnt-label-6 .label-main, div.pnt-label-6 .label-main, li.pnt-label-6 .label-main {
  width: 6rem;
  flex-shrink: 0; }

ul.pnt-label-7 .label-main, div.pnt-label-7 .label-main, li.pnt-label-7 .label-main {
  width: 7rem;
  flex-shrink: 0; }

ul.pnt-label-8 .label-main, div.pnt-label-8 .label-main, li.pnt-label-8 .label-main {
  width: 8rem;
  flex-shrink: 0; }

ul.pnt-label-9 .label-main, div.pnt-label-9 .label-main, li.pnt-label-9 .label-main {
  width: 9rem;
  flex-shrink: 0; }

ul.pnt-label-10 .label-main, div.pnt-label-10 .label-main, li.pnt-label-10 .label-main {
  width: 10rem;
  flex-shrink: 0; }

ul.pnt-label-11 .label-main, div.pnt-label-11 .label-main, li.pnt-label-11 .label-main {
  width: 11rem;
  flex-shrink: 0; }

ul.pnt-label-12 .label-main, div.pnt-label-12 .label-main, li.pnt-label-12 .label-main {
  width: 12rem;
  flex-shrink: 0; }

ul.pnt-label-13 .label-main, div.pnt-label-13 .label-main, li.pnt-label-13 .label-main {
  width: 13rem;
  flex-shrink: 0; }

ul.pnt-label-top .pnt-label--group, div.pnt-label-top .pnt-label--group, li.pnt-label-top .pnt-label--group {
  align-items: flex-start; }

@media (min-width: 570px) {
  ul.label-top-web .pnt-label--group, div.label-top-web .pnt-label--group, li.label-top-web .pnt-label--group {
    align-items: flex-start; } }

@media (max-width: 570px) {
  ul.label-top-mobile .pnt-label--group, div.label-top-mobile .pnt-label--group, li.label-top-mobile .pnt-label--group {
    align-items: flex-start; } }

@media (min-width: 570px) {
  ul.label-col-web .pnt-label--group, div.label-col-web .pnt-label--group, li.label-col-web .pnt-label--group {
    flex-direction: column;
    align-items: flex-start; }
    ul.label-col-web .pnt-label--group .label-main, div.label-col-web .pnt-label--group .label-main, li.label-col-web .pnt-label--group .label-main {
      width: 100% !important;
      flex-shrink: 0; } }

@media (max-width: 570px) {
  ul.label-col-mobile .pnt-label--group, div.label-col-mobile .pnt-label--group, li.label-col-mobile .pnt-label--group {
    flex-direction: column;
    align-items: flex-start; }
    ul.label-col-mobile .pnt-label--group .label-main, div.label-col-mobile .pnt-label--group .label-main, li.label-col-mobile .pnt-label--group .label-main {
      width: 100% !important;
      flex-shrink: 0; }
    ul.label-col-mobile .pnt-label--group .label-options, div.label-col-mobile .pnt-label--group .label-options, li.label-col-mobile .pnt-label--group .label-options {
      width: 100% !important; } }

.pnt-label--group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  position: relative; }
  .pnt-label--group .label-main {
    min-width: 5rem;
    overflow: visible;
    word-break: keep-all;
    line-height: 1.3;
    font-size: 0.8rem;
    font-weight: 700;
    text-align: left;
    text-transform: capitalize;
    position: relative; }
    @media (max-width: 758px) {
      .pnt-label--group .label-main {
        width: 6rem;
        flex-shrink: 0; } }
    .pnt-label--group .label-main.label-dot {
      display: inline-block;
      padding-left: .8rem; }
      .pnt-label--group .label-main.label-dot::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background-color: #FF7800;
        display: inline-block;
        position: absolute;
        top: .5rem;
        left: 0;
        transform: translateY(-50%); }
    .pnt-label--group .label-main.label-dot-black {
      display: inline-block;
      padding-left: .8rem; }
      .pnt-label--group .label-main.label-dot-black::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50px;
        background-color: #A8A7AC;
        display: inline-block;
        position: absolute;
        top: .5rem;
        left: 0;
        transform: translateY(-50%); }
    .pnt-label--group .label-main.label-line {
      position: relative; }
      .pnt-label--group .label-main.label-line::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -8px;
        width: 2rem;
        height: 2px;
        border-radius: 10px;
        background-color: #ECEAF2; }
    .pnt-label--group .label-main.label-line-long::before {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      width: 100%;
      height: 1px;
      background-color: #CAC8CF; }
    .pnt-label--group .label-main.label-info .info-box {
      display: none; }
    .pnt-label--group .label-main.label-info .icon-info-fill {
      transform: translateY(-10%) scale(0.7);
      opacity: .3;
      position: relative; }
      .pnt-label--group .label-main.label-info .icon-info-fill:hover {
        opacity: 1; }
      .pnt-label--group .label-main.label-info .icon-info-fill:hover + .info-box {
        display: block;
        z-index: 10;
        position: absolute;
        left: 0px;
        bottom: -50px;
        transform: scale(0.9);
        min-width: 8rem;
        min-height: 2rem;
        padding: .5rem;
        background-color: white;
        border: 1px solid #ECEAF2;
        box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
        border-radius: 5px;
        color: rgba(0, 0, 0, 0.75);
        line-height: auto;
        font-size: 0.8rem;
        font-weight: 400; }
    .pnt-label--group .label-main.label-must {
      color: #FF7800;
      position: relative; }
      .pnt-label--group .label-main.label-must::after {
        content: '*';
        position: absolute;
        top: -.5rem;
        left: -.3rem; }
  .pnt-label--group .label-options {
    flex: 1 1 auto;
    line-height: 1.3;
    font-size: 0.8rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 5px;
    position: relative; }

.pagination {
  display: flex;
  justify-content: flex-end; }
  .pagination .now {
    line-height: auto;
    font-size: 0.9rem;
    font-weight: 700;
    color: #FF7800; }
  .pagination .whole {
    line-height: auto;
    font-size: 0.9rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6); }

.pnt-table {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  height: 300px; }
  .pnt-table .border-box {
    overflow: auto hidden;
    border-top: 1.4px solid black;
    border-bottom: 1.4px solid black;
    height: 100%; }
    .pnt-table .border-box .thead {
      overflow: visible; }
      .pnt-table .border-box .thead .tr {
        height: 36px;
        display: flex;
        padding: 0; }
        .pnt-table .border-box .thead .tr .th {
          display: flex;
          flex: 1 1 1%;
          align-items: center;
          justify-content: center;
          background-color: #d2d2fd;
          border-right: 1px solid #F6F6F9;
          font-weight: bold; }
          .pnt-table .border-box .thead .tr .th:last-child {
            border-right: none; }
    .pnt-table .border-box .tbody {
      min-height: 10rem;
      height: calc(100% - 36px);
      position: relative;
      background-color: white; }
      .pnt-table .border-box .tbody > div {
        padding: 0;
        overflow: visible;
        height: 100%; }
        .pnt-table .border-box .tbody > div > div {
          position: relative;
          height: 100%;
          width: 100%;
          overflow: overlay;
          will-change: transform;
          direction: ltr; }
          .pnt-table .border-box .tbody > div > div > div .tr {
            display: flex;
            width: 100%;
            height: 38px;
            position: absolute;
            left: 0; }
            .pnt-table .border-box .tbody > div > div > div .tr .td {
              display: flex;
              flex: 1 1 1%;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              border-bottom: 1px solid #F6F6F9;
              border-right: 1px solid #F6F6F9; }
              .pnt-table .border-box .tbody > div > div > div .tr .td:last-child {
                border-right: none; }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(2n) {
              background-color: rgba(0, 0, 0, 0.02); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(1) {
              top: 0; }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(2) {
              top: calc(36px * 1); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(3) {
              top: calc(36px * 2); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(4) {
              top: calc(36px * 3); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(5) {
              top: calc(36px * 4); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(6) {
              top: calc(36px * 5); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(7) {
              top: calc(36px * 6); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(8) {
              top: calc(36px * 7); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(9) {
              top: calc(36px * 8); }
            .pnt-table .border-box .tbody > div > div > div .tr:nth-child(10) {
              top: calc(36px * 9); }
  .pnt-table.table-clickable .border-box .tr:hover {
    background-color: #ebecf3;
    transition: all 0.2s; }
  .pnt-table.table-clickable .border-box .tr.active {
    background-color: #3E66FB; }
    .pnt-table.table-clickable .border-box .tr.active .td {
      color: white; }

.form-disable {
  filter: none !important;
  cursor: default !important; }
  .form-disable:hover {
    cursor: default !important; }
  .form-disable.pnt-btn {
    box-shadow: 0 0 0 1px #ECEAF2 inset;
    background-color: #F6F6F9;
    color: rgba(0, 0, 0, 0.45); }
    .form-disable.pnt-btn:hover {
      filter: none; }
    .form-disable.pnt-btn span {
      filter: unset !important;
      opacity: .4; }
  .form-disable.pnt-input--group .pnt-input {
    background-color: #F6F6F9;
    color: rgba(0, 0, 0, 0.45);
    filter: none; }
    .form-disable.pnt-input--group .pnt-input::placeholder {
      color: rgba(0, 0, 0, 0.25); }
    .form-disable.pnt-input--group .pnt-input:hover {
      box-shadow: none; }
  .form-disable.pnt-select--group:hover {
    cursor: default !important; }
  .form-disable.pnt-select--group .select__btn {
    background-color: #F6F6F9;
    box-shadow: none;
    filter: none !important; }
    .form-disable.pnt-select--group .select__btn:hover {
      cursor: default !important; }
    .form-disable.pnt-select--group .select__btn .cont-wrap {
      color: rgba(0, 0, 0, 0.45); }
  .form-disable.pnt-checkbox .checkbox-effect {
    background-color: #F6F6F9; }
  .form-disable.pnt-checkbox p {
    opacity: .5; }
  .form-disable.pnt-toggle {
    box-sizing: border-box; }
    .form-disable.pnt-toggle .slider:hover {
      cursor: default !important; }
    .form-disable.pnt-toggle .slider::after {
      color: black;
      z-index: 2;
      content: 'X';
      opacity: .2;
      font-size: .8rem;
      font-weight: 900;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 7px; }
    .form-disable.pnt-toggle input:checked + .slider::after {
      left: unset;
      right: 7px; }

.form-must.pnt-select--group, .form-must.pnt-input--group {
  position: relative; }
  .form-must.pnt-select--group input, .form-must.pnt-input--group input {
    border: 1px solid #FF7800; }
  .form-must.pnt-select--group .select__btn, .form-must.pnt-input--group .select__btn {
    border: 1px solid #FF7800; }
    .form-must.pnt-select--group .select__btn:hover, .form-must.pnt-input--group .select__btn:hover {
      border: 1px solid orangered; }
  .form-must.pnt-select--group::after, .form-must.pnt-input--group::after {
    display: block;
    content: '*';
    position: absolute;
    top: -.8rem;
    left: -.3rem;
    line-height: auto;
    font-size: 1.1rem;
    font-weight: 400;
    color: #ff3333; }

.pnt-input--group, .pnt-select--group, .pnt-btn,
.pnt-checkbox, .pnt-radio, .pnt-nameplate, .pnt-file--group {
  height: 38px; }
  .pnt-input--group.form-h-small, .pnt-select--group.form-h-small, .pnt-btn.form-h-small,
  .pnt-checkbox.form-h-small, .pnt-radio.form-h-small, .pnt-nameplate.form-h-small, .pnt-file--group.form-h-small {
    height: 32px;
    line-height: auto;
    font-size: 0.7rem;
    font-weight: 400; }
  .pnt-input--group.form-h-big, .pnt-select--group.form-h-big, .pnt-btn.form-h-big,
  .pnt-checkbox.form-h-big, .pnt-radio.form-h-big, .pnt-nameplate.form-h-big, .pnt-file--group.form-h-big {
    height: 44px; }

.pnt-toggle {
  height: 22px; }
  .pnt-toggle.form-h-small {
    height: 14px; }
  .pnt-toggle.form-h-big {
    height: 38px;
    width: 4rem; }
    .pnt-toggle.form-h-big input:checked + .slider::before {
      transform: translateX(2.3rem) translateY(-50%); }
    .pnt-toggle.form-h-big .slider::before {
      height: 28px;
      width: 20px;
      border-radius: 5px; }
    .pnt-toggle.form-h-big p {
      line-height: auto;
      font-size: 0.9rem;
      font-weight: 700; }
      .pnt-toggle.form-h-big p:nth-of-type(1) {
        right: 10px !important; }
      .pnt-toggle.form-h-big p:nth-of-type(2) {
        left: 10px !important; }

.pnt-checkbox .checkbox-effect {
  width: 22px;
  height: 22px; }

.pnt-checkbox.form-h-small .checkbox-effect {
  width: 14px;
  height: 14px; }
  .pnt-checkbox.form-h-small .checkbox-effect::before {
    transform: rotate(45deg) translateX(-20%) translateY(-80%) scale(0.6); }
  .pnt-checkbox.form-h-small .checkbox-effect::after {
    transform: rotate(305deg) translateX(-20%) translateY(-60%) scale(0.6); }

.pnt-checkbox.form-h-big p {
  line-height: auto;
  font-size: 1rem;
  font-weight: 400; }

.pnt-checkbox.form-h-big .checkbox-effect {
  width: 38px;
  height: 38px; }
  .pnt-checkbox.form-h-big .checkbox-effect::before {
    transform: rotate(45deg) translateX(-80%) translateY(-80%) scale(1.5); }
  .pnt-checkbox.form-h-big .checkbox-effect::after {
    transform: rotate(305deg) translateX(-40%) translateY(-80%) scale(1.3); }

.pnt-input--group input, .pnt-input--group input::placeholder, .pnt-select--group .cont-wrap, .pnt-select--group a,
.pnt-btn, .pnt-checkbox p, .pnt-nameplate .cont-wrap, .pnt-radio label, .pnt-toggle p,
.pnt-table--paging__wrap span, .pnt-table .th, .pnt-table .td, .pnt-txt {
  line-height: auto;
  font-size: 0.8rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75); }

.pnt-input--group input, .pnt-select--group .select__btn, .pnt-checkbox, .pnt-toggle {
  transition: all 0.2s; }
  .pnt-input--group input:hover, .pnt-select--group .select__btn:hover, .pnt-checkbox:hover, .pnt-toggle:hover {
    filter: brightness(0.98);
    transition: all 0.2s; }

#root .app-container.app-theme-pnt-dark input:focus, #root .app-container.app-theme-pnt-dark input:active, #root .app-container.app-theme-pnt-dark input:focus-visible, .app-container.app-theme-pnt-plant input:focus, .app-container.app-theme-pnt-plant input:active, .app-container.app-theme-pnt-plant input:focus-visible {
  border: 1px solid white; }

#root .app-container.app-theme-pnt-dark .form-disable input:focus, #root .app-container.app-theme-pnt-dark .form-disable input:active, #root .app-container.app-theme-pnt-dark .form-disable input:focus-visible, .app-container.app-theme-pnt-plant .form-disable input:focus, .app-container.app-theme-pnt-plant .form-disable input:active, .app-container.app-theme-pnt-plant .form-disable input:focus-visible {
  border: 1px solid #656467 !important; }

#root .app-container.app-theme-pnt-dark .pnt-select--group .select__options, .app-container.app-theme-pnt-plant .pnt-select--group .select__options {
  border: none; }

#root .app-container.app-theme-pnt-dark .pnt-btn.form-disable, .app-container.app-theme-pnt-plant .pnt-btn.form-disable {
  border: 1px solid #ECEAF2; }
  #root .app-container.app-theme-pnt-dark .pnt-btn.form-disable span, .app-container.app-theme-pnt-plant .pnt-btn.form-disable span {
    filter: invert(1) !important; }

#root .app-container.app-theme-pnt-dark .pnt-label--group .label-line::before, #root .app-container.app-theme-pnt-dark .pnt-label--group .label-line-long::before, .app-container.app-theme-pnt-plant .pnt-label--group .label-line::before, .app-container.app-theme-pnt-plant .pnt-label--group .label-line-long::before {
  background-color: #656467; }

#root .app-container.app-theme-pnt-dark .pnt-label--group span[class*="icon-"], .app-container.app-theme-pnt-plant .pnt-label--group span[class*="icon-"] {
  opacity: .5; }

#root .app-container.app-theme-pnt-dark span[class*="icon-"], .app-container.app-theme-pnt-plant span[class*="icon-"] {
  filter: invert(1); }

#root .app-container.app-theme-pnt-dark .pnt-btn span[class*="icon-"], .app-container.app-theme-pnt-plant .pnt-btn span[class*="icon-"] {
  filter: none; }

#root .app-container.app-theme-pnt-dark .pnt-input--group span[class*="icon-"], .app-container.app-theme-pnt-plant .pnt-input--group span[class*="icon-"] {
  filter: invert(1); }
