$breakpoint-mobile: 570px;
$breakpoint-tablet: 758px;
$breakpoint-labtop: 1024px;
$breakpoint-desktop: 1200px;

.leaflet-container {
    background-color: transparent;
    width: 100%;
    height: 100%;
}

.leaflet-top.leaflet-left {
    display: flex;
    flex-wrap: wrap;


    .custom-leaflet-control {
        //width: 100%;
        height: 100%;

        a {
            width: 30px;
            height: 30px;
        }
    }

    .control-container {
        >*{
            margin-right: .4rem;
        }
    }

    @media(max-width: $breakpoint-tablet){
        .control-container{
            display:flex;
            align-items: flex-start;
            flex-direction: column;
            flex-basis: 100%;
            width: min-content;
            max-width: max-content;

            .item-container{
                margin-left: 0;
            }

            >*{
                margin-bottom: .4rem;
            }
        }

    }
}

.geofence-name-label {
    font-size: 0.85rem;
    font-weight: 500;
    //color: #eee;
    text-shadow: -1px 0 #f2f1f6, 0 1px #f2f1f6, 1px 0 #f2f1f6, 0 -1px #f2f1f6;
    word-break: keep-all;
    width: initial !important;
    height: initial !important;

    background-color: initial;
    border: initial;
    box-shadow: initial;
    padding: unset;
    &:before {
        content: none;
    }
}

.category-marker {
    .marker-img {
        width: 100%;
        height: 100%;
    }
    .marker-img.replace-img {
        display: flex;
        justify-content: center;
        align-items: center;
        &>div {
            width: 100%;
            height: 100%;
            background-color: #3076c1;
            border-radius: 50%;
        }
    }
    .marker-label {
        background-color: rgba(255,255,255, .8);
        border-radius: 3px;
        position: absolute;
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
    }

    // Ani marker
    .green {
        color: #009313;
    }

    .orange {
        color: #f17c57fa;
    }

    .blue {
        color: #3652D9;
    }

    .rose {
        color:#FF66CC;
    }

    .circle {
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #0000001a;
    }

    .square {
        border-radius: 10%;
        box-shadow: 0 0 1px 1px #0000001a;
    }

    .pulse-S {
        animation: pulse-animation-S 3s infinite;
    }

    .pulse-U {
        animation: pulse-animation-U 3s infinite;
    }

    .pulse-F {
        animation: pulse-animation-F 3s infinite;
    }

    .pulse-S.rssi {
        animation: pulse-animation-S-rssi 3s infinite;
    }

    .pulse-U.rssi {
        animation: pulse-animation-U-rssi 3s infinite;
    }

    .pulse-F.rssi {
        animation: pulse-animation-F-rssi 3s infinite;
    }
}


@keyframes pulse-animation-S {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-U {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-F {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }
    30% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    60% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-S-rssi {
    0% {
        box-shadow: 0 0 0 0;
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-U-rssi {
    0% {
        box-shadow: 0 0 0 0;
    }
    70% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes pulse-animation-F-rssi {
    0% {
        box-shadow: 0 0 0 0;
    }
    30% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    60% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.leaflet-control-layers-expanded{
    .leaflet-control-layers-overlays{
        & label {
            & .leaflet-control-layers-selector {
                margin: 0 5px 0 0;
            }

            & div {
                display: flex;
                justify-content: center;
                align-items: center;

                & span {
                    vertical-align: middle;
                }
            }
        }
    }
}