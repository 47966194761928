.react-checkbox-tree {
  font-size: .88rem;

  .rct-text {
    align-items: center;
  }
  .rct-collapse.rct-collapse-btn {
    display: flex;
    align-items: center;
  }

  .rct-node-icon {
    padding: 0;
  }

  .rct-node-leaf>.rct-text>.rct-collapse>.rct-icon {
    width: 7.5px
  }

}
