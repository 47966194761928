//Menu
.select-menu{
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(218, 50%, 10%, 0.1), 0 4px 11px hsla(218, 50%, 10%, 0.1);
  margin-top: 8px;
  position: absolute;
  z-index: 2;
  min-width: 100%;
  max-width: 14rem;
}

.align-right .select-menu {
  left: 100%;
  transform: translateX(-100%);
}

//Blanket
.select-blanket{
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

//Dropdown
.select-dropdown{
  position: relative;
  font-size: 0.8rem;
}

//Dropdown Indicator
.select-dropdown-indicator{
  color: gray;
  height: 24px;
  width: 32px;
}

.styled-option{
  padding: 4px 8px;
  &:hover, &:focus, &:active{
    background-color: #ebebeb;
  }
  & > label {
    margin-bottom: 0;
  }
}

.styled-option-label{
  padding: 4px 8px;
  text-align: center;
  color: #999999;
}
