@charset "UTF-8";
/* Css link*/
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #FF7800; }

.color-secondary {
  color: #3E66FB; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #FFC107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #DCD9E1; }

.color-gray {
  color: #A8A7AC; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #FF7800; }
  .bg-brand.bg-light {
    background-color: #ffe4cc; }

.bg-secondary {
  background-color: #3E66FB; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #FFC107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #A8A7AC; }
  .bg-gray.bg-light {
    background-color: #CAC8CF; }

.bg-lightgray {
  background-color: #DCD9E1; }
  .bg-lightgray.bg-light {
    background-color: #ECEAF2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: .5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: .5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: .5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: .5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.filter_ver3 {
  position: relative; }
  @media (max-width: 758px) {
    .filter_ver3 .filter__now, .filter_ver3 .filter__this-page {
      padding: .3rem 1rem;
      display: flex;
      flex-direction: column !important;
      position: relative;
      line-height: auto;
      font-size: 0.8rem;
      font-weight: 400; }
      .filter_ver3 .filter__now .filter-list, .filter_ver3 .filter__this-page .filter-list {
        padding: .5rem 0;
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        transition: all .5s;
        max-height: 20rem;
        opacity: 1; }
        .filter_ver3 .filter__now .filter-list > div, .filter_ver3 .filter__this-page .filter-list > div {
          width: 100%;
          max-width: 100%; }
        .filter_ver3 .filter__now .filter-list.folded, .filter_ver3 .filter__this-page .filter-list.folded {
          transition: all .5s;
          opacity: 0.5;
          max-height: 0;
          padding: 0;
          margin: 0;
          border: transparent;
          overflow: hidden; }
      .filter_ver3 .filter__now .filter-function, .filter_ver3 .filter__this-page .filter-function {
        display: block;
        position: absolute;
        right: 1rem; }
    .filter_ver3 .filter__more {
      display: none;
      padding: 1rem;
      flex: 1 1 auto;
      width: 100%; }
      .filter_ver3 .filter__more button.filter-add-btn {
        margin-left: .3rem;
        height: 0; }
      .filter_ver3 .filter__more.on {
        display: block; } }
  .filter_ver3 .filter__now {
    border-bottom: 1px solid #ECEAF2; }
  .filter_ver3 .filter__now, .filter_ver3 .filter__this-page {
    padding: .3rem 1rem;
    display: flex;
    flex-direction: row;
    line-height: auto;
    font-size: 0.8rem;
    font-weight: 400; }
    .filter_ver3 .filter__now .filter__info, .filter_ver3 .filter__this-page .filter__info {
      flex: 0 0 4rem;
      max-height: 38px;
      margin-right: 1rem; }
      .filter_ver3 .filter__now .filter__info > .pnt-label--group, .filter_ver3 .filter__this-page .filter__info > .pnt-label--group {
        border-radius: 5px;
        background-color: #d8e0fe;
        box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
        color: #3252c9;
        width: 4rem;
        height: 2rem;
        display: flex;
        align-items: center; }
        .filter_ver3 .filter__now .filter__info > .pnt-label--group > .label-main, .filter_ver3 .filter__this-page .filter__info > .pnt-label--group > .label-main {
          min-width: unset;
          width: 100% !important;
          text-align: center; }
    .filter_ver3 .filter__now .filter-list, .filter_ver3 .filter__this-page .filter-list {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      min-width: 0;
      align-items: center;
      gap: 5px; }
    .filter_ver3 .filter__now .filter-function, .filter_ver3 .filter__this-page .filter-function {
      flex: 0 0 auto;
      margin-left: 5px; }
      @media (max-width: 570px) {
        .filter_ver3 .filter__now .filter-function, .filter_ver3 .filter__this-page .filter-function {
          padding: 0 !important; } }
  .filter_ver3 .filter__this-page .filter-list {
    align-items: unset;
    flex-wrap: nowrap; }
  .filter_ver3 .filter__more {
    display: none;
    width: 20rem;
    padding: 1rem;
    flex: 1 1 auto;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
    border-radius: 5px;
    z-index: 10; }
    .filter_ver3 .filter__more .pnt-label--group {
      margin-top: .5rem; }
    .filter_ver3 .filter__more .more-activated, .filter_ver3 .filter__more .more-none {
      max-height: 200px;
      overflow: auto; }
    .filter_ver3 .filter__more .pnt-checkbox {
      width: 100%; }
    .filter_ver3 .filter__more.on {
      display: block; }

.filter-options__tree {
  padding: 1rem !important;
  width: 20rem !important; }
  @media (max-width: 758px) {
    .filter-options__tree {
      width: calc(100% - 2px); } }
  .filter-options__tree > div {
    margin-bottom: 1rem; }
  .filter-options__tree > div:last-child {
    margin: 0; }
  .filter-options__tree ul {
    padding: 0;
    margin: 0; }
    .filter-options__tree ul li .pnt-select-label {
      padding: .3rem;
      min-height: 20px;
      width: 100%; }

.filter-options__check {
  padding: 1rem !important;
  width: 13rem !important; }
  @media (max-width: 758px) {
    .filter-options__check {
      width: calc(100% - 2px); } }
  .filter-options__check > div {
    margin-bottom: 1rem; }
  .filter-options__check > div:last-child {
    margin: 0; }
  .filter-options__check ul {
    padding: 0;
    margin: 0; }
    .filter-options__check ul li .pnt-select-label {
      padding: .3rem;
      min-height: 20px;
      width: 100%; }

.filter-options__search {
  padding: 1rem !important;
  width: 15rem !important; }
  @media (max-width: 758px) {
    .filter-options__search {
      width: calc(100% - 2px); } }
  .filter-options__search > div {
    margin-bottom: 1rem; }
  .filter-options__search > div:last-child {
    margin: 0; }
  .filter-options__search ul {
    padding: 0;
    margin: 0; }
    .filter-options__search ul li .pnt-select-label {
      padding: .3rem;
      min-height: 20px;
      width: 100%; }

.filter-options__date {
  padding: 1rem !important;
  width: 20rem !important;
  padding: 0 !important;
  overflow: visible; }
  @media (max-width: 758px) {
    .filter-options__date {
      width: calc(100% - 2px); } }
  .filter-options__date > div {
    margin-bottom: 1rem; }
  .filter-options__date > div:last-child {
    margin: 0; }
  .filter-options__date ul {
    padding: 0;
    margin: 0; }
    .filter-options__date ul li .pnt-select-label {
      padding: .3rem;
      min-height: 20px;
      width: 100%; }
  .filter-options__date .select__option-list {
    margin: 0; }
    .filter-options__date .select__option-list .option-list__btn {
      opacity: .4;
      border: 1px solid #ECEAF2;
      padding: .6rem 1rem; }
      .filter-options__date .select__option-list .option-list__btn * {
        display: inline-block;
        vertical-align: middle;
        margin: 0; }
    .filter-options__date .select__option-list .option-list__hidden-box {
      display: none;
      background-color: #F6F6F9;
      padding: 1rem; }
    .filter-options__date .select__option-list.checked .option-list__btn {
      opacity: 1; }
    .filter-options__date .select__option-list.checked .option-list__hidden-box {
      display: block; }
      .filter-options__date .select__option-list.checked .option-list__hidden-box .pnt-txt {
        width: 5.5rem; }
  .filter-options__date .pnt-input {
    width: 4rem; }
  .filter-options__date .pnt-select--group {
    max-width: unset; }

.filter_ver4 {
  padding: 1rem 0 2rem 0; }
  .filter_ver4 .filter-box {
    margin-bottom: 1.5rem; }
    .filter_ver4 .filter-box .filter-list {
      flex-wrap: wrap; }
  .filter_ver4 .search-box .search-wrap {
    border: 1px solid #FF7800;
    background-color: #FF7800;
    border-radius: 5px;
    display: flex;
    width: 36.5rem; }
    .filter_ver4 .search-box .search-wrap button, .filter_ver4 .search-box .search-wrap input {
      border-radius: 0; }
    .filter_ver4 .search-box .search-wrap button:first-child {
      border-radius: 5px 0 0 5px; }
    .filter_ver4 .search-box .search-wrap button:last-child {
      border-radius: 0 5px 5px 0; }
    .filter_ver4 .search-box .search-wrap .input-wrap {
      display: flex;
      width: 100%; }
      .filter_ver4 .search-box .search-wrap .input-wrap .pnt-input--group {
        width: 100%; }
    @media (max-width: 570px) {
      .filter_ver4 .search-box .search-wrap .input-wrap input {
        border-radius: 0 0 0 5px; }
      .filter_ver4 .search-box .search-wrap .input-wrap button {
        border-radius: 0 0 5px 0; } }
  .filter_ver4 .filter-label {
    line-height: 38px;
    font-size: 0.8rem;
    font-weight: 700;
    white-space: nowrap;
    padding: 0 1rem;
    height: 38px;
    border-radius: 5px;
    background-color: #d8e0fe;
    box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03); }
  .filter_ver4 .none-label {
    line-height: 38px;
    font-size: 0.8rem;
    font-weight: 700;
    height: 38px; }
  .filter_ver4 .filter-list {
    gap: .5rem;
    display: flex;
    align-items: center;
    gap: 5px; }
  @media (max-width: 570px) {
    .filter_ver4 .filter-function .filter-open {
      display: block !important; } }
  .filter_ver4 .filter-function {
    display: flex;
    flex: 0 0 auto;
    gap: .2rem; }
    .filter_ver4 .filter-function .filter-open {
      display: none; }
    n >
.filter_ver4 .filter-function button {
      box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03); }
  @media (max-width: 570px) {
    .filter_ver4 .filter-box {
      flex-direction: column; }
      .filter_ver4 .filter-box .filter-list {
        width: 100%;
        transition: all .5s;
        max-height: 20rem;
        opacity: 1; }
        .filter_ver4 .filter-box .filter-list > div {
          width: 100%;
          max-width: 100%; }
        .filter_ver4 .filter-box .filter-list.folded {
          transition: all .5s;
          opacity: 0.8;
          max-height: 38px;
          padding: 0;
          margin: 0;
          border: transparent;
          overflow: hidden; }
      .filter_ver4 .filter-box .filter-function {
        position: absolute;
        right: 1rem; }
    .filter_ver4 .search-box {
      flex-direction: column; }
      .filter_ver4 .search-box .search-wrap {
        display: flex;
        flex-direction: column;
        width: 100%; }
        .filter_ver4 .search-box .search-wrap button:first-child {
          border-radius: 5px 5px 0 0; }
        .filter_ver4 .search-box .search-wrap .pnt-input--group, .filter_ver4 .search-box .search-wrap > div {
          width: 100%; }
      .filter_ver4 .search-box .filter-function {
        position: absolute;
        right: 1rem; } }
