@charset "UTF-8";
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #FF7800; }

.color-secondary {
  color: #3E66FB; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #FFC107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #DCD9E1; }

.color-gray {
  color: #A8A7AC; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #FF7800; }
  .bg-brand.bg-light {
    background-color: #ffe4cc; }

.bg-secondary {
  background-color: #3E66FB; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #FFC107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #A8A7AC; }
  .bg-gray.bg-light {
    background-color: #CAC8CF; }

.bg-lightgray {
  background-color: #DCD9E1; }
  .bg-lightgray.bg-light {
    background-color: #ECEAF2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: .5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: .5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: .5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: .5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-trigger {
  height: 38px;
  border: 1px solid #CAC8CF;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18); }
  .leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-trigger::after {
    font-size: 1px;
    margin: 0;
    font-size: 0.8rem;
    color: #A8A7AC !important;
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translateY(-60%) scale(0.8); }
  .leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-trigger .tag-list .tag-item {
    line-height: 1;
    font-size: 0.8rem;
    font-weight: 400; }
    .leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-trigger .tag-list .tag-item input {
      border: none; }

.leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-content {
  padding: .5rem 0 .5rem .5rem; }
  .leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-content * {
    color: rgba(0, 0, 0, 0.75) !important; }
  .leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-content ul li {
    border-radius: 5px;
    transition: all 0.2s; }
    .leaflet-control .react-dropdown-tree-select .dropdown.radio-select .dropdown-content ul li:hover {
      background-color: #ECEAF2 !important; }

.leaflet-control.control-container {
  display: flex;
  gap: 10px; }
  .leaflet-control.control-container > * {
    margin: 0 !important;
    box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18); }
  .leaflet-control.control-container .item-container {
    height: 38px;
    border: 1px solid #CAC8CF;
    display: flex;
    gap: .3rem;
    padding: .5rem;
    margin: 0; }
    .leaflet-control.control-container .item-container .map-text {
      line-height: auto;
      font-size: 0.8rem;
      font-weight: 400; }
  .leaflet-control.control-container .pnt-btn {
    background-color: #DCD9E1 !important; }

.leaflet-control.leaflet-control-layers {
  border: 1px solid #CAC8CF !important;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.leaflet-control.custom-leaflet-control {
  border: 1px solid #CAC8CF !important;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.leaflet-control.leaflet-control-zoom.leaflet-bar {
  border: 1px solid #CAC8CF !important;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

.header-btn-lg::before {
  background: #ECEAF2; }

.header-btn-lg .widget-content {
  padding: .5rem 0 .5rem .9rem;
  border-radius: 5px;
  background-color: transparent;
  transition: background-color .3s; }
  .header-btn-lg .widget-content:hover {
    transition: background-color .3s;
    background-color: rgba(255, 255, 255, 0.1); }

.header-btn-lg .btn-group .btn-link {
  text-decoration: none !important;
  display: flex;
  gap: .4rem;
  align-items: center; }
  .header-btn-lg .btn-group .btn-link .flx-col {
    text-align: left; }
  .header-btn-lg .btn-group .btn-link .widget-heading {
    line-height: auto;
    font-size: 0.8rem;
    font-weight: 200;
    font-weight: 600;
    opacity: 1; }
  .header-btn-lg .btn-group .btn-link .widget-sub-heading {
    font-size: .7rem;
    font-weight: 300;
    opacity: .5;
    margin-top: -1px; }
  .header-btn-lg .btn-group .btn-link .widget-btn {
    filter: grayscale(100%) brightness(1.5); }

.header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right {
  background-color: white;
  width: 400px;
  padding: 0; }
  .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header {
    background-color: rgba(68, 60, 185, 0.6);
    border-bottom: 1px solid #9eade7;
    margin: 0; }
    .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .widget-content-wrapper {
      padding: 1rem 1.2rem 1rem 1.4rem; }
      .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .widget-content-wrapper .profile-txt {
        display: flex;
        flex-direction: column;
        gap: .2rem; }
        .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .widget-content-wrapper .profile-txt .user-name {
          line-height: auto;
          font-size: 1rem;
          font-weight: 400;
          color: white; }
        .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .widget-content-wrapper .profile-txt .unread-msg {
          line-height: auto;
          font-size: 0.8rem;
          font-weight: 200;
          color: rgba(255, 255, 255, 0.6); }
          .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .widget-content-wrapper .profile-txt .unread-msg b {
            color: white; }
  .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 120px;
    margin: 0;
    padding-top: .5rem;
    border-radius: 5px;
    background-color: transparent;
    gap: 1.5rem;
    cursor: pointer;
    transition: all 0.2s; }
    .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item:first-child::before {
      display: none; }
    .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item::before {
      content: '';
      width: 1px;
      height: calc(100% - 20px);
      position: absolute;
      top: 10px;
      left: 0;
      display: block;
      opacity: .5;
      background: #ECEAF2; }
    .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item:hover {
      background-color: white;
      transition: all 0.2s; }
    .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item span[class*="icon-"] {
      transform: scale(2.5);
      background-color: #F6F6F9;
      border-radius: .2rem;
      box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18); }
    .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item .setting-name {
      color: grey;
      font-size: 0.7rem; }

.pnt-modal .modal-title {
  line-height: auto;
  font-size: 1.2rem;
  font-weight: 400; }

.pnt-modal .modal-body {
  line-height: auto;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.75); }
  .pnt-modal .modal-body h6 {
    padding: 1rem .1rem; }
  .pnt-modal .modal-body ul {
    padding: 0; }
    .pnt-modal .modal-body ul li {
      padding: .5rem; }
      .pnt-modal .modal-body ul li div {
        padding-left: 20px;
        line-height: auto;
        font-size: 1rem;
        font-weight: 400; }
      .pnt-modal .modal-body ul li.active {
        background-color: #3E66FB; }
